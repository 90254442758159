// start countdown
export function startCountdown({timeTotal, startTime}) {
  return {
    type: 'INIT_TIME',
    timeTotal,
    startTime,
  }
}

// count one down
export function countDown() {
  return {
    type: 'COUNTDOWN',
  }
}

// toggle countdown
export function togglePlay() {
  return {
    type: 'TOGGLE_PLAY'
  }
}

// times up
export function timesUp() {
  return {
    type: 'TIMES_UP'
  }
}

// toggle countdown
export function toggleSound() {
  return {
    type: 'TOGGLE_SOUND'
  }
}