import { createStore } from 'redux';

// root reducer
import rootReducer from './reducers/index';
import suggestions from './data/suggestions';

// load suggestions to default state
const defaultState = {
  suggestions,
  time: {
    timeLeft: 1,
    timeSpent: 0,
    timeTotal: 0,
    startTime: null,
    paused: false,
    timesUp: false,
  },
  sound: true,
};

const store = createStore(rootReducer, defaultState);

export default store;
