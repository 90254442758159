import React, { Component } from 'react';
import Suggestion from './Suggestion.js';
import TimeInput from './TimeInput';
import suggestions from '../data/suggestions';

class Input extends Component {
  render() {
    return (
      <div className="Input">
        <TimeInput />
        <Suggestion suggestions={suggestions} />
      </div>
    );
  }
}

export default Input;
