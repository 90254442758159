export function parseTimeToSeconds(mins) {
    if (mins.indexOf(':') > -1) {
      return 60 * parseInt(mins.substr(0,mins.indexOf(':')),10) + parseInt(mins.substr(mins.indexOf(':')+1),10);
    }
    return 60 * parseInt(mins,10);
  }

export function parseTimeToMinutes(secs) {
    let seconds = ((secs % 60 < 10) ? '0' + secs % 60 : secs % 60)
    return Math.floor(secs / 60) + ':' + seconds;
}

export class AudioPlayer {
  constructor() {
    this.playAudio = this.playAudio.bind(this);
    this.audio = new Audio(process.env.PUBLIC_URL + '4beep.ogg');
    this.audio.load();
  }
  playAudio() {
    const audioPromise = this.audio.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          // autoplay started
        })
        .catch(err => {
          // catch dom exception
          console.info(err)
        })
    }
  }
  playAlarm() {
    this.playAudio();
  }
}