import React, { Component } from 'react';
import SoundOn from '../img/soundon.svg';

class Sound extends Component {
  render() {
    const { toggleSound, sound } = this.props;
    const word = sound ? 'Disable' : 'Enable';
    return (
      <div className="Sound" onClick={toggleSound}>
        <img src={SoundOn} alt="{word} Sound" className={word} />
        <span>{word} Sound</span>
      </div>
    );
  }
}

export default Sound;
