import React, { Component } from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { time } from '../data/words';

class TimeInput extends Component {
  constructor() {
    super();
    // default to 5
    this.state = {time: 5};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({time: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.history.push(`/${this.state.time}`);
  }
  render() {
    return (
      <div className="TimeInput">
        <form onSubmit={this.handleSubmit}><input type="text" placeholder={time} onChange={this.handleChange} /><Link to={`/${this.state.time}`}>&rarr;</Link></form>
      </div>
    );
  }
}

export default withRouter(TimeInput);
