function time(state = {}, action) {
  const newState = Object.assign({}, state);
  switch(action.type) {
    case 'INIT_TIME':
      // Set initial times.
      newState.timeTotal = action.timeTotal;
      newState.timeLeft = action.timeTotal;
      newState.startTime = action.startTime;
      return newState;
    case 'COUNTDOWN':
      const {timeSpent, startTime} = state;
      // Check for lagging and get correct time, we don't rely on time tick but check time ourself.
      const now = +(new Date());
      const timeSpentSinceStart = Math.round((now - startTime) / 1000);
      // console.log('Time spent during this start', timeSpentSinceStart);
      newState.timeLeft = newState.timeTotal - timeSpent - timeSpentSinceStart;
      return newState;
    case 'TOGGLE_PLAY':
      newState.paused = !newState.paused;
      if (!newState.paused) {
        // Set total time spent.
        newState.timeSpent = newState.timeTotal - newState.timeLeft;
        // Set new starting point.
        newState.startTime = +(new Date());
      }
      return newState;
    case 'TIMES_UP':
      newState.timesUp = true;
      return newState;
    case 'CONFIRM':
      newState.timesUp = false;
      return newState;
    default:
      return state;
  }
}
export default time;
