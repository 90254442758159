import React, { Component } from 'react';
import { format} from 'date-fns';
import {parseTimeToMinutes} from '../helpers';
import { started } from '../data/words';

class Play extends Component {

  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = e => {
    // Space for toggle play
    if (e.keyCode === 32) {
      this.props.togglePlay();
    }
  }

  resetTime() {
    window.location.reload();
  }

  render() {
    const {timeTotal} = this.props.time;
    const {initialStartTime} = this.props;
    return (
      <div className="Controls">
        <div className="Reset" onClick={this.resetTime}>&#8634;</div>
        <div className="TimeInfo">
          <div>
            <p>{parseTimeToMinutes(timeTotal)}</p>
            <p>{started} {initialStartTime && format(initialStartTime, 'HH:mm:ss')}</p>
          </div>
        </div>
        <div className="Play" onClick={this.props.togglePlay}>&#9658; &#10073;&#10073; <span></span></div>
      </div>
    );
  }
}

export default Play;
