import React, {Component} from 'react';
import * as workerTimers from 'worker-timers';
import { appName } from '../data/words';
import NoSleep from 'nosleep.js';
import { withRouter } from "react-router";

import {parseTimeToSeconds, parseTimeToMinutes, AudioPlayer} from '../helpers';

import Play from './Play';
import ProgressBar from './ProgressBar';

class Counter extends Component {
  componentDidMount() {
    // set countdown time
    const { mins } = this.props.match.params;
    const timeTotal = parseTimeToSeconds(mins);
    const startTime = +(new Date());
    this.initialStartTime = startTime;
    this.props.startCountdown({timeTotal, startTime});
    // start counting down every second
    this.timer = workerTimers.setInterval(this.updateTimeDisplay.bind(this), 1000);
    this.noSleep = new NoSleep();
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      this.noSleep.enable();
    }.bind(this), false);
  }
  componentWillUnmount() {
    workerTimers.clearInterval(this.timer);
  }
  updateTimeDisplay() {
    if (!this.props.time.timeLeft <= 0 && !this.props.time.paused) {
      // this.props.countDown({timeTotal: this.props.timeTotal, timeSpent: this.props.timeSpent});
      this.props.countDown();
      document.title = `${appName}  ►  ${parseTimeToMinutes(this.props.time.timeLeft)}`;
    } else if (this.props.time.paused) {
      // paused
      document.title = `${appName}  ❙❙  ${parseTimeToMinutes(this.props.time.timeLeft)}`;
    }
    else {
      this.timeUp();
    }
  }
  timeUp() {
    // Play sound if sound is enabled.
    if (this.props.sound) {
      const ap = new AudioPlayer();
      ap.playAlarm();
    }
    workerTimers.clearInterval(this.timer);
    console.log('Time is up!');
    document.title = `${appName}  ❙❙ Time's Up! ${parseTimeToMinutes(this.props.time.timeLeft)}`;    
    // Send Notification if enabled.
    if ('Notification' in window) {
      const text = 'Time is up!'
      const notification = new Notification('Timer', { body: text });
    }
    setTimeout(() => {alert('Time is up!');}, 500);
    // Disable wake lock (does not need to be wrapped in any user input event handler)
    this.noSleep.disable();
  }
  render() {
    const {timeLeft, timeSpent, timeTotal} = this.props.time;
    const initialStartTime = this.initialStartTime;
    return (
      <div className="Counter">
        <div className="Time">{parseTimeToMinutes(timeLeft)}</div>
        <ProgressBar total={timeTotal} spent={timeLeft} />
        <Play {...this.props} initialStartTime={initialStartTime} />
      </div>
    );
  }
}

export default withRouter(Counter);
