import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Sound from './Sound';
import Notifications from './Notification';
import Examples from './Examples';
import { appName, slogan } from '../data/words';


import Input from './Input';
import Counter from './Counter';

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

// const About = (props) => (<div className="About">{about}</div>);

class Main extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <div className="App__header">
            <div className="App__logo">
              <Link to="/"><h2>{appName}</h2></Link>
              <p className="App__intro">{slogan}</p>
            </div>
            <div className="App__sound">
              <Sound toggleSound={this.props.toggleSound} sound={this.props.sound} />
            </div>
            <div className="App__notifications">
              <Notifications />
            </div>
            {/* <div className="App__preheader">
              <Link to="/about/1">About</Link>
            </div> */}
          </div>
            <Route exact path="/" component={Input} />
            <Route path="/v1/*" render={ (props) => <Redirect to={'/'} /> } />
            <Route path="/v2/*" render={ (props) => <Redirect to={'/'} /> } />
            <Route path="/:mins(\d*:?\d+)" render={ (props) => <Counter {...props} {...this.props} key={props.location.key} />} />
          <Examples />
        </div>
      </Router>
    );
  }
}

export default Main;
