let appName = 'Timer-App';
let slogan = 'Online countdown timer in the browser'
let metaDescription = 'Timer-App.com is an online countdown timer in the browser.'
let time = 'Time';
let about = 'Timer-App is a browser-based countdown timer.';
let started = 'Started at';

if (process.env.REACT_APP_DOMAIN === 'ajast.in') {
  appName = 'Ajast.in';
  slogan = 'Selaimessa toimiva ajastin';
  metaDescription = 'Ajast.in on selaimessa toimiva ajastin, joka laskee aikaa ja hälyttää kun aika on loppu.';
  time = 'min:sek';
  about = 'Timer-App is a browser-based countdown timer.';
  started = 'Aloitettu';
}

export {appName, slogan, metaDescription, time, about, started}
