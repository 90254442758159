import React, { Component } from 'react';
import Bell from '../img/bell.svg';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.askNotificationPermission = this.askNotificationPermission.bind(this);
    }
    notificationStyle = 'disabled';
    askNotificationPermission() {
        const that = this;
        // function to actually ask the permissions
        function handlePermission(permission) {
            // Whatever the user answers, we make sure Chrome stores the information
            if (!('permission' in Notification)) {
                Notification.permission = permission;
            }
            // set the button to shown or hidden, depending on what the user answers
            if (Notification.permission === 'denied' || Notification.permission === 'default') {
                that.notificationStyle = 'disabled';
            } else {
                that.notificationStyle = 'enabled';
            }
        }
        function checkNotificationPromise() {
            try {
            Notification.requestPermission().then();
            } catch(e) {
            return false;
            }
            return true;
        }
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
        } else {
            if (checkNotificationPromise()) {
            Notification.requestPermission()
            .then((permission) => {
                handlePermission(permission);
            })
            } else {
            Notification.requestPermission(function(permission) {
                handlePermission(permission);
            });
            }
        }
  }
  render() {
    return (
      <div className={`Notification ${this.notificationStyle}`} onClick={this.askNotificationPermission}>
            <img src={Bell} alt="Notify" />
            <span>Enable Notifications</span>
      </div>
    );
  }
}

export default Notifications;
