import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Examples extends Component {
  render() {
    return (
      <div className="Examples">
          <Link to="/20">Countdown timer for 20 minutes</Link>
          <Link to="/25">Pomodoro for 25 minutes</Link>
          <Link to="/5">5 minutes break timer</Link>
          <Link to="/10">10 minutes egg timer</Link>
          <Link to="/60">1 hour countdown</Link>
      </div>
    );
  }
}

export default Examples;
